import * as React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { SmallButton } from './style'

interface BadgeProps {
  label: string
  onClick?: () => {}
  color?: string
  isNumber?: boolean
  circle?: boolean
  withHover?: boolean
  blink?: boolean
  className?: string
}

const Badge: React.FunctionComponent<BadgeProps & InjectedIntlProps> = ({
  label,
  onClick,
  color,
  isNumber,
  circle,
  blink,
  ...rest
}) => (
  <SmallButton blink={blink} onClick={onClick} color={color} circle={circle} {...rest}>
    {isNumber ? label : <FormattedMessage id={label} />}
  </SmallButton>
)

export default injectIntl(Badge)
