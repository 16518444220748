import Spacer from '@berlitz/spacer'
import NotificationCard from '@berlitzplatforms/micro.ui.notification-card'
import PortalStudentProfile from '@classes/StudentProfile'
import FormattedHTMLMessage from '@components/FormattedHTMLMessage'
import { StoreProps } from '@interfaces/StoreState'
import { updateDashboardActiveProgram } from '@redux/actions/dashboard'
import { useRouter } from 'next/router'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

const AtRiskNotificationCard: React.FC<{ maxWidth?: string; noButton?: boolean }> = ({
  maxWidth = '100%',
  noButton,
}) => {
  const intl = useIntl()
  const router = useRouter()
  const dispatch = useDispatch()
  const profile = useSelector(
    ({ userProfile }: StoreProps) => new PortalStudentProfile(userProfile.info.StudentProfile?.StudentProfileInfo)
  )

  return profile.AtRiskProgram ? (
    <>
      <NotificationCard
        type="warning"
        maxWidth={maxWidth}
        simpleTitle={intl.formatMessage({ id: 'Stay on-track, book your lessons now' })}
        simpleMessage={
          <>
            <FormattedHTMLMessage
              id={`You have {lessonCount} unbooked lessons for {materialName}.`}
              defaultMessage={`You have {lessonCount} unbooked lessons for {materialName}.`}
              values={{
                lessonCount: profile.AtRiskProgram?.getLessonsLeft(),
                materialName: profile?.Materials?.[profile.AtRiskProgram?.LMSMaterialId || '']?.MaterialName,
              }}
            />

            <FormattedHTMLMessage
              id={`Without booked lessons, you may not finish your {materialName} course in time. Book all your lessons now, keep working towards fluency.`}
              defaultMessage={`Without booked lessons, you may not finish your {materialName} course in time. Book all your lessons now, keep working towards fluency.`}
              values={{
                materialName: profile?.Materials?.[profile.AtRiskProgram?.LMSMaterialId || '']?.MaterialName,
              }}
            />
          </>
        }
        messageAlignment="left"
        buttonLabel={noButton ? undefined : intl.formatMessage({ id: 'Book lessons' })}
        onButtonClick={
          noButton
            ? undefined
            : () => {
                console.log(profile.AtRiskProgram?.ProgramId)
                dispatch(updateDashboardActiveProgram(profile.AtRiskProgram?.ProgramId || ''))
                router.push(profile.AtRiskProgram?.hasBulkOnlyFeature() ? '/scheduling/instructors' : '/scheduling')
              }
        }
        noDefaultImage={true}
        containerWidth="95%"
      ></NotificationCard>
      <Spacer size="lg" />
    </>
  ) : null
}

export default AtRiskNotificationCard
