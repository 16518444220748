import styled, { css } from 'styled-components'
import Button from '../Button'

interface BtnProps {
  color?: string
  circle?: boolean
  blink?: boolean
}

export const SmallButton = styled(Button)<BtnProps>`
  padding: ${({ theme }) => theme.space.xxs};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  line-height: 1;
  vertical-align: middle;

  ${({ circle }) =>
    circle &&
    css`
      border-radius: 100px;
      min-width: 20px;
    `}

  &:focus {
    outline: 0;
  }

  ${({ blink }) =>
    blink &&
    css`
      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: white;
          text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow:
            0.25em 0 0 white,
            0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow:
            0.25em 0 0 white,
            0.5em 0 0 white;
        }
      }

      transition: all 0.5s ease-in-out;
      animation: blink normal 1.5s infinite ease-in-out;
      &:after {
        content: '.';
        animation: dots 1s steps(5, end) infinite;
        margin-right: 5px;
      }
    `}
`
