import { DATE_FORMAT } from '@utils/constants'
import { EVENT_STATUS } from '@utils/constants/events'
import { uniq } from 'lodash'
import moment from 'moment'

export const getDropdownOptions = (arr: string[] = []) =>
  arr.map(value => ({
    id: value,
    label: value,
    value,
  }))

export const getEventDates = (startDate: string, endDate: string) => {
  const start = moment(startDate, DATE_FORMAT.BASIC)
  const end = moment(endDate, DATE_FORMAT.BASIC)
  let months: string[] = [start.format(DATE_FORMAT.MY)]

  while (start < end) {
    months = [...months, start.add(1, 'month').format(DATE_FORMAT.MY)]
  }

  return uniq(months)
}

export const utcfyDate = (date: moment.Moment) => (date.utc().toISOString() || '').replace('T', '+')

export const isEventActive = (EventStatus?: string) =>
  EventStatus === EVENT_STATUS.ACTIVE || EventStatus === EVENT_STATUS.NEW || EventStatus === EVENT_STATUS.PENDING
