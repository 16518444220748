import { IGetEventAttendee } from '@interfaces/Events'
import moment from 'moment'
import { TCalendarItemEventStatus } from '@berlitzplatforms/micro.ui.calendar-item-event/lib'
import { DATE_FORMAT } from '@utils/constants'
import { ATTENDEE_STATUS, EVENT_TYPES, GROUP_CONVERSATION_CLASS } from '@utils/constants/events'
import { isEventActive } from '@layouts/Events/helpers'
import { getEventTimeFormat } from '@utils/helpers/time-format'

class PortalBerlitzEventAttendee {
  event: IGetEventAttendee
  hasBookedSameType: boolean
  userTZ: string

  constructor(event: IGetEventAttendee, hasBookedSameType = false, userTZ: string) {
    this.event = event
    this.hasBookedSameType = hasBookedSameType
    this.userTZ = userTZ
  }

  get Id() {
    return this.event.berlitzEventAttendeeInfo.EventId
  }

  get AttendeeId() {
    return this.event.berlitzEventAttendeeInfo.EventAttendeeId
  }

  get Status(): TCalendarItemEventStatus {
    if (this.DisplayJoinButton) {
      return 'join-cancel'
    }

    if (this.isPastEvent()) {
      return 'disabled'
    }

    return 'reserved'
  }

  get MyCalendarStatus(): TCalendarItemEventStatus {
    if (this.isPastEvent() && !this.DisplayJoinButton) {
      return 'disabled'
    }

    if (this.DisplayJoinButton) {
      return 'join-cancel'
    }

    return 'join-cancel'
  }

  get Type() {
    return this.event.berlitzEventAttendeeInfo.EventType
  }

  get RawType() {
    return this.Type === GROUP_CONVERSATION_CLASS ? EVENT_TYPES.MEETING : this.Type
  }

  get Language() {
    return this.event.berlitzEventAttendeeInfo.Language
  }

  get StartDatetime() {
    return moment(this.event.berlitzEventAttendeeInfo.StartDatetime).tz(this.userTZ).clone()
  }

  get EndDatetime() {
    return moment(this.event.berlitzEventAttendeeInfo.EndDatetime).tz(this.userTZ).clone()
  }

  get Hosts() {
    return (this.event?.berlitzEventAttendeeInfo?.Hosts || [])?.map(({ Id = '', Name = '', Avatar = '' }) => ({
      id: Id,
      name: Name,
      avatar: Avatar,
    }))
  }

  get Date() {
    return this.StartDatetime.format(DATE_FORMAT.DEFAULT)
  }

  get Time() {
    return `${this.EventStartTime} - ${this.EventEndTime}`
  }

  get EventStartTime() {
    return this.StartDatetime.clone().format(DATE_FORMAT.hM)
  }

  get EventEndTime() {
    return this.EndDatetime.clone().format(DATE_FORMAT.HMa)
  }

  get SeatsLeft() {
    return this.event.berlitzEventAttendeeInfo.SeatsLeft || 0
  }

  get StartMonthYear() {
    return this.StartDatetime.format(DATE_FORMAT.MY)
  }

  get EndMonthYear() {
    return this.EndDatetime.format(DATE_FORMAT.MY)
  }

  get RawStartDatetime() {
    return this.event.berlitzEventAttendeeInfo.StartDatetime
  }

  get RawEndDatetime() {
    return this.event.berlitzEventAttendeeInfo.EndDatetime
  }

  get IsOrientation() {
    return this.Type === EVENT_TYPES.ORIENTATION
  }

  get DisplayJoinButton() {
    const now = moment.tz(this.userTZ)
    const start = this.StartDatetime.clone().subtract(5, 'minutes')

    return isEventActive(this.event.berlitzEventAttendeeInfo.EventStatus) && now.isBetween(start, this.EndDatetime)
  }

  get IsCancelled() {
    return this.event.berlitzEventAttendeeInfo?.AttendeeStatus === ATTENDEE_STATUS.CANCELLED
  }

  get DisplayName() {
    return this.event.berlitzEventAttendeeInfo.EventDisplayName || ''
  }

  isPastEvent = () => this.EndDatetime.isBefore(moment.tz(this.userTZ))

  getTimeWithPortalTimeFormat = (isTimeFormatDisabled, convertToUserTimeFormats) =>
    getEventTimeFormat(
      {
        default: this.Time,
        start: this.StartDatetime.clone().format(DATE_FORMAT.HMa),
        end: this.EventEndTime,
      },
      isTimeFormatDisabled,
      convertToUserTimeFormats
    )
}

export default PortalBerlitzEventAttendee
