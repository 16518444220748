// form translations
export const REGISTRATIONS = 'Registrations'
export const EVENTS = 'Events'
export const LANGUAGE = 'Language'
export const DATE = 'Date'
export const SORT = 'Sort'
export const SELECT = 'Select'
export const ALL = 'All'
export const THIS_MONTH = 'This month'
export const EVENT_DATE_ASC = 'Event date (Ascending)'
export const EVENT_DATE_DESC = 'Event date (Descending)'
export const EMPTY_EVENTS = 'You have no upcoming events for this month.'

// CalendarItemEvent translations
export const DELIVERY_LANGUAGE = 'Delivery language'
export const MORE_INFO = 'More info'
export const SELF_TECH_CHECK = 'Self tech check'
export const JOIN_ONLINE = 'Join online'
export const CANCEL = 'Cancel'
export const RESERVE_SEAT = 'Reserve seat'
export const COUNT_SEATS_LEFT = {
  id: 'count-seats-left',
  defaultMessage: '{count, plural, =0 {Full} one {# seat left} other {# seats left}}',
}
export const WHY_CANT_RESERVE = "Why can't I reserve a seat?"
export const SEAT_RESERVED = 'Seat reserved'
export const FULL = 'Full'

// EventDetails translations
export const ADDITIONAL_INFO = 'Additional info'
export const NO_EVENT_DETAILS_AVAILABLE = 'No event details available.'
export const PLEASE_CHECK_BACK_LATER = 'Please check back later.'

// modal translations
export const CANCEL_RESERVED_SEAT = 'Cancel reserved seat'
export const ARE_YOU_SURE_CANCEL_SEAT = 'Are you sure you want to cancel this seat?'
export const KEEP_RESERVED_SEAT = 'Keep reserved seat'
export const SEAT_UNAVAILABLE = 'Seat unavailable'
export const OOPS_SEAT_UNAVAILABLE = 'Oops, this seat is no longer available. Please go back and select another option.'
export const OK = 'Ok'
export const RESERVING = 'Reserving seat...'
export const CANCELLING = 'Cancelling seat...'
export const PLEASE_WAIT = 'Please wait a moment.'
export const RESERVE_CONFLICT = 'Reserve conflict'
export const RESERVE_CONFLICT_DESC = 'You have another event reserved at this time.'
export const RESERVE_CONFLICT_INSTRUCTIONS = 'Try searching again with a different time.'
export const WELL_SEE_YOU_SOON = "We'll see you soon"
export const GO_TO_MY_CALENDAR = 'Go to My calendar'
export const VIEW_MORE_EVENTS = 'View more events'
export const CANCEL_SEAT_SUCCESS = 'Your reserved seat has been cancelled'
export const COPY_NEEDED = 'Copy needed'
export const WHY_CANT_RESERVE_DESC =
  'Only one event type can be reserved at a time. You have already reserved a seat for this event type.'
export const ONLY_ONE_EVENT_TYPE_RESERVE = {
  id: 'only-one-event-type-reserve',
  defaultMessage:
    'Only one {eventType} event can be reserved at a time. You have already reserved a seat for {eventType}.',
}
export const API_ERROR = 'Oh no! Something went wrong'
export const API_ERROR_DESC = 'Please try again later or contact the Support team.'

export const TRANSLATIONS = {
  REGISTRATIONS,
  EVENTS,
  LANGUAGE,
  DATE,
  SORT,
  SELECT,
  ALL,
  THIS_MONTH,
  EVENT_DATE_ASC,
  EVENT_DATE_DESC,
  EMPTY_EVENTS,

  // CalendarItemEvent translations
  DELIVERY_LANGUAGE,
  MORE_INFO,
  SELF_TECH_CHECK,
  JOIN_ONLINE,
  CANCEL,
  RESERVE_SEAT,
  COUNT_SEATS_LEFT,
  WHY_CANT_RESERVE,
  SEAT_RESERVED,
  FULL,

  // EventDetails translations
  ADDITIONAL_INFO,
  NO_EVENT_DETAILS_AVAILABLE,
  PLEASE_CHECK_BACK_LATER,

  // modal translations
  CANCEL_RESERVED_SEAT,
  ARE_YOU_SURE_CANCEL_SEAT,
  KEEP_RESERVED_SEAT,
  SEAT_UNAVAILABLE,
  OOPS_SEAT_UNAVAILABLE,
  OK,
  RESERVING,
  CANCELLING,
  PLEASE_WAIT,
  RESERVE_CONFLICT,
  RESERVE_CONFLICT_DESC,
  RESERVE_CONFLICT_INSTRUCTIONS,
  WELL_SEE_YOU_SOON,
  GO_TO_MY_CALENDAR,
  VIEW_MORE_EVENTS,
  CANCEL_SEAT_SUCCESS,
  COPY_NEEDED,
  WHY_CANT_RESERVE_DESC,
  ONLY_ONE_EVENT_TYPE_RESERVE,
  API_ERROR,
  API_ERROR_DESC,
}

// api messages
export const ERR_CONFLICT =
  'The event is not booked. The attendee has a conflicting schedule on the given Event Date Time.'
export const ERR_UNAVAILABLE = 'Event -- Invalid Id'
export const CANCELLED = 'Cancelled'
export const API_MESSAGE = {
  ERR_CONFLICT,
  ERR_UNAVAILABLE,
  CANCELLED,
}

export const EVENT_ATTENDEE_ROLE_HOST = 'Host'
export const EVENT_ATTENDEE_ROLE_GUEST = 'Guest'
export const EVENT_ATTENDEE_ROLES = [EVENT_ATTENDEE_ROLE_HOST, EVENT_ATTENDEE_ROLE_GUEST]
