import gql from 'graphql-tag'

export const POST_EVENT_ATTENDEE = gql`
  mutation PostEventAttendee($postEventAttendeeInput: PostEventAttendeeInput!) {
    postEventAttendee(postEventAttendeeInput: $postEventAttendeeInput) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
      ServiceResult {
        berlitzEventAttendeeInfo {
          Type
          StudentId
          StartDatetime
          RegistrationId
          RecordId
          ProgramId
          Message
          EventId
          EventAttendeeId
          EndDatetime
        }
      }
    }
  }
`

export const PUT_EVENT_ATTENDEE = gql`
  mutation PutEventAttendee($putEventAttendeeInput: [PutEventAttendeeInput!]!) {
    putEventAttendee(putEventAttendeeInput: $putEventAttendeeInput) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
      }
    }
  }
`
