import { useMutation } from '@apollo/react-hooks'
import JoinLessonButton from '@components/JoinLessonButton'
import { IBerlitzEventAttendeeInfo, IPutEventAttendeeData, IPutEventAttendeeEvents } from '@interfaces/Events'
import { PUT_EVENT_ATTENDEE } from '@mutations/events'
import { ATTENDEE_STATUS } from '@utils/constants/events'
import React from 'react'

export interface IEventJoinOnlineBtn {
  berlitzEventAttendeeInfo?: IBerlitzEventAttendeeInfo
  fullWidth?: boolean
}

const EventJoinOnlineBtn: React.FC<IEventJoinOnlineBtn> = ({ berlitzEventAttendeeInfo, fullWidth = true }) => {
  const [putEventAttendee] = useMutation<IPutEventAttendeeData, IPutEventAttendeeEvents>(PUT_EVENT_ATTENDEE, {
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetBerlitzEventAttendee'],
    awaitRefetchQueries: true,
  })

  return (
    <JoinLessonButton
      className="join-lesson"
      lesson={{
        StartTime: berlitzEventAttendeeInfo?.StartDatetime,
        EndTime: berlitzEventAttendeeInfo?.EndDatetime,
        VirtualClassRoomProvider: berlitzEventAttendeeInfo?.VirtualClassRoomProvider,
        OnlineLessonURL: berlitzEventAttendeeInfo?.OnlineLessonURL,
        ProgramId: berlitzEventAttendeeInfo?.EventId,
        ProgramName: berlitzEventAttendeeInfo?.EventName,
        SessionId: berlitzEventAttendeeInfo?.EventAttendeeId,
      }}
      fullWidth={fullWidth}
      onCompleted={() => {
        putEventAttendee({
          variables: {
            putEventAttendeeInput: [
              {
                berlitzEventAttendeeInfo: {
                  EventAttendeeId: berlitzEventAttendeeInfo?.EventAttendeeId || '',
                  AttendeeStatus: ATTENDEE_STATUS.ATTENDED,
                },
              },
            ],
          },
        })
      }}
    />
  )
}

export default EventJoinOnlineBtn
